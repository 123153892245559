export const environment = {
  production: false,
  isProduction: false,
  TP_MENU: 0,
  OAUTH_URL: 'https://oauth.api.dev.nexuscloud.com.br/api',
  API_URL_CADASTRO: 'https://cadastro.api.dev.nexuscloud.com.br/api',
  API_URL_COMPRAS: 'https://compras.api.dev.nexuscloud.com.br/api',
  API_URL_ESTOQUE: 'https://estoque.api.dev.nexuscloud.com.br/api',
  API_URL_FINANCEIRO: 'https://financeiro.api.dev.nexuscloud.com.br/api',
  API_URL_VENDAS: 'https://vendas.api.dev.nexuscloud.com.br/api',
  API_URL_PRECOS: 'https://precos.api.dev.nexuscloud.com.br/api',
  API_URL_FISCAL: 'https://fiscal.api.dev.nexuscloud.com.br/api',
  API_URL_COMISSOES: 'https://comissoes.api.dev.nexuscloud.com.br/api',
  ASTUTUS_API_URL: 'https://astutus.api.dev.nexuscloud.com.br/api',
  S3_API_URL: 'https://s3.api.dev.nexuscloud.com.br/api',
  API_URL_REPORT: 'https://report.api.dev.nexuscloud.com.br/api',
  API_URL_PRESTO: 'https://presto.api.dev.nexuscloud.com.br/api',
  API_URL_SYNC: 'https://sync.api.dev.nexuscloud.com.br/api',
  API_URL_MONITOR_NFE: 'https://monitor-nfe.api.dev.nexuscloud.com.br/api',
  API_URL_DOMINIO: 'https://dominio.api.dev.nexuscloud.com.br/api',
  INTEGRACAO_DOMINIO_URL: 'https://homologacao.nexuscloud.com.br/#/integracao/dominio',
  API_URL_ECOMMERCE: 'https://ecommerce.api.dev.nexuscloud.com.br/api',
};
